<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1">
        <el-breadcrumb separator="/" style="margin-top: 1.5rem;">
          <el-breadcrumb-item :to="{ path: '/sggl' }"><span style="color: rgba(43, 222, 201, 0.4);">施工管理</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item><span style="color: rgba(43, 222, 201, 1);">施工信息</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="h2" @click="back()">
        返回
      </div>
    </div>
    <div class="hd1">
      项目基本信息
      <div class="h1" style="cursor: pointer;" @click="Xs">
        <img style="width: 3rem; height: 3rem;" src="../../static/xg.png">
      </div>
      <!-- <div class="h2">
        生成报告
      </div> -->
    </div>
    <div class="hd2">
      <div id="" style="display: flex; align-items: center;">
        <span class="h1">
          公司名称：{{Data.projectName}}
        </span>
        <span class="h1">
          公司地址：{{Data.address}}
        </span>
        <span class="h1">
          创建时间：{{Data.gmtCreate}}
        </span>
        <!-- <span class="h1">
          现场负责人：{{Data.director}}
        </span> -->
        <!-- <span class="h1">
          安装人员：{{Data.installer[0]}}
        </span> -->
      </div>
      <div id="" style="display: flex; align-items: center;">
        <!-- <span class="h1">
          安装位置：{{Data.address}}
        </span>
        <span class="h1">
          创建时间：{{Data.gmtCreate}}
        </span> -->
        <span class="h1">
          工程状态：{{xmzt[Data.state]}}
        </span>
      </div>
    </div>
    <div class="hd1">
      设备展示
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd bg">
        <!-- 账号密码 -->
        <div>
          <span class="hd_1">
            序号
          </span>
          <span class="hd_2" style="width: 19.4%;">
            <div style="margin-left: 1rem;">
              安装项目名称
            </div>
          </span>
          <span class="hd_2" style="width: 19.4%;">
            <div style="margin-left: 1rem;">
              规格型号
            </div>
          </span>
          <span class="hd_2" style="width: 19.4%;">
            <div style="margin-left: 1rem;">
              创建日期
            </div>
          </span>
          <span class="hd_2" style="width: 15.5%;">
            <div style="margin-left: 1rem;">
              安装状态
            </div>
          </span>
          <span class="hd_2" style="width: 19.6%;">
            <div style="margin-left: 1rem;">
              查看信息
            </div>
          </span>
        </div>
      </div>

      <!-- 主体 -->
      <div style="margin-top: 2rem;">
        <div class="main" v-for="(item,index) in list" :key="index" @click="To(item.id)" :class="index % 2 == 0?'bg1':'bg2'">
          <div style="width: 100%;display: flex;">
            <span class="m_1">
              {{index + 1}}
            </span>
            <span class="m_2" style="width: 19.4%;">
              <div style="margin-left: 1rem;">
                <div class="item-show">{{item.projectName}}</div>
                <div class="hidden-text">{{item.projectName}}</div>
              </div>
            </span>
            <span class="m_2" style="width: 19.4%;">
              <div style="margin-left: 1rem;">
                {{item.specifications}}
              </div>
            </span>
            <span class="m_2" style="width: 19.4%;">
              <div style="margin-left: 1rem;">
                {{item.gmtCreate}}
              </div>
            </span>
            <span class="m_2" style="width: 15.5%;">
              <div style="margin-left: 1rem;">
                {{azzt[item.state]}}
              </div>
              <div v-if="item.state == 1" style="position: absolute; right: 1rem;top: 0; color: deeppink; cursor: pointer;" @click.stop="Fg(item.id)">
                返工
              </div>
            </span>
            <span class="m_2" style="width: 19.6%;">
              <div style="margin-left: 1rem;">
                查看信息
              </div>
            </span>
          </div>
        </div>
      </div>

    </div>

   <!-- 翻页 -->
   <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
     <!-- <span class="demonstration">完整功能</span> -->
     <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
       :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
     </el-pagination>
   </div>


    <!-- 新增弹窗 -->
    <el-dialog title="操作" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="负责人:" :label-width="formLabelWidth">
          <el-input v-model="form.director" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装位置:" :label-width="formLabelWidth">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" :label-width="formLabelWidth">
          <el-input v-model="form.projectName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="项目状态:" :label-width="formLabelWidth">
          <el-input v-model="form.state" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-radio v-model="form.state" :label="0">进行中</el-radio>
        <el-radio v-model="form.state" :label="1">已完成</el-radio>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="Form()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'gsgly',
    data() {
      return {
        dialogFormVisible: false,
        azzt: ['安装中', '已完成', '异常'],
        xmzt: ['进行中', '已完成'],
        list: [{
            name: '在线检测',
            qr: false
          },
          {
            name: '项目管理',
            qr: false
          }, {
            name: '设备管理',
            qr: false
          }, {
            name: '施工管理',
            qr: false
          }, {
            name: '告警管理',
            qr: false
          }, {
            name: '账号管理',
            qr: false
          }


        ],
        value: true,
        currentPage4: 1,
        id: null,
        total: null,
        Data: {},
        form: {
          "address": "重庆市",
          "director": "张三",
          "id": 0,
          "projectName": "川渝蓝铁高速新干线交通大桥",
          "state": 0
        },
        formLabelWidth: '12rem',
        obj: {
          "page": 1,
          "projectId": 0,
          "size": 10
        }
      }
    },
    created() {
      console.log(this.$route.query.id);
      this.id = this.$route.query.id
      this.obj.projectId = this.id
      this.info()
      this.Sblb()
    },
    methods: {
      Fg(id){
        this.$confirm('此操作将返工, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$newGet('/construction/rework'+id).then(res => {
                    if (res.code == 2000) {
                      this.$message({
                        message: res.message,
                        type: 'success'
                      });
                      this.Sblb()
                    } else {
                      this.$message({
                        message: res.message,
                        type: 'warning'
                      });
                    }
                  })
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消返工'
                  });
                });

      },
      back() {
        this.$router.go(-1); //返回上一层
      },
      Xs(){
        this.dialogFormVisible = true
        this.form = {
          "address": this.Data.address,
          "director": this.Data.director,
          "id":  this.Data.id,
          "projectName": this.Data.projectName,
          "state": this.Data.state
        }
      },
      Form() {
        console.log(this.form);
        this.$ajax('edxm', 'post', this.form).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.dialogFormVisible=false
            this.info()
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
          // this.total = res.total
        })
      },
      To(id) {
        this.$router.push('/sggl_2?id=' + id)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.obj.size = val
        this.Sblb()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.obj.page = val
        this.Sblb()
      },
      xian(item) {
        item.qr = !item.qr
      },
      info() {
        this.$ajax('xmxq', 'get', this.id).then(res => {
          console.log(res);
          this.Data = res.data
          // this.total = res.total
        })
      },
      Sblb() {
        this.$ajax('sblb', 'post', this.obj).then(res => {
          console.log(res,'');
          this.list = res.data.records
          this.total = res.data.total
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.1);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }
  .m1 {
    position: relative;
    color: #FFFFFF;
    margin: 1rem 0 0 1rem;
    width: 156.6rem;
    // overflow: auto;

    // height: 59.4rem;

    .hd {
      position: relative;
      width: 100%;
      height: 5rem;


      .h1 {
        display: inline-block;
        // width: 11.5rem;
        height: 5rem;
        line-height: 5rem;
        padding-left: 1rem;
        font-size: 1.8rem;
      }

      .h2 {
        position: absolute;
        top: 1rem;
        right: 2rem;
        width: 11.5rem;
        height: 4.2rem;
        // background-color: rgba(43, 201, 222, 1);
        border: 1px solid rgba(43, 201, 222, 1);
        line-height: 4.2rem;
        text-align: center;
        border-radius: .5rem;
        cursor: pointer;
      }
    }

    .hd1 {
      position: relative;
      width: 100%;
      margin-left: 1rem;
      margin-top: 1rem;
      height: 5rem;
      line-height: 5rem;
      margin-bottom: 1rem;
      color: rgba(43, 201, 222, 1);

      .h1 {
        position: absolute;
        top: 50%;
        right: 5.5rem;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;
      }

      .h2 {
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);
        width: 11.5rem;
        height: 4.2rem;
        background-color: rgba(43, 201, 222, 0.4);
        line-height: 4.2rem;
        text-align: center;
        border-radius: .5rem;
      }
    }

    .hd2 {
      height: 8.4rem;
      margin-left: 1rem;

      .h1 {
        display: inline-block;
        width: 46.6rem;
        height: 5rem;
        margin-right: 5rem;
      }
    }

    .lb {
      position: relative;
      // border: 1px solid #2BDEC9;
      margin-top: 1rem;
      font-size: 1.4rem;
      // overflow: auto;

      .hd {
        width: 100%;
        height: 3.6rem;
        line-height: 3.6rem;
        // padding-left: 1rem;
        font-size: 1.4rem;

        // border-bottom: 1px solid #2BDEC9;
        .hd_1 {
          display: inline-block;
          width: 4.5%;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          // margin-right: .3%;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .hd_2 {
          display: inline-block;
          width: 94.5%;
          height: 4.2rem;
          line-height: 4.2rem;
          // background-color: rgba(43, 201, 222, 0.6);
          // margin-right: .3%;

        }

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }
      }

      .hd:last-child {
        // border-bottom: none;
      }

      .qx {
        position: absolute;
        top: .1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: .3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: .5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: .4rem;
          margin-left: 1rem;
        }
      }
    }

    .main {
      position: relative;
      width: 100%;
      // height: 4.2rem;
      // margin-top: 1rem;
      padding: .5rem 0;

      .m_1 {
        display: inline-block;
        width: 4.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        margin-right: .3%;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
      }

      .m_2 {
        position: relative;
        display: inline-block;
        width: 94.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-right: .3%;

        .item-show {
          height: 4.2rem;
          line-height: 4.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .hidden-text {
          font-size: 1.7rem;
          position: relative;

          // width: 25rem;
          line-height: 2.2rem;
          z-index: -2;
          top: -4.2rem;
          padding: 8px 8px 8px 8px;
          border-radius: .2rem;
        }
      }
      .m_2:hover .hidden-text {
        // display: show;
        background-color: rgba(8, 157, 157, 1);
        z-index: 999;
      }

      .m_3 {
        position: relative;
        box-sizing: border-box;
        float: right;
        width: 94.5%;
        height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-top: .5rem;
        line-height: 4.2rem;

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }

        .qx {
          position: absolute;
          top: .1rem;
          left: 50rem;
          display: inline-block;

          .lst {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background-color: rgba(43, 201, 222, 0.6);
            text-align: center;
            border-radius: .3rem;
            margin-right: 1rem;
          }
        }

        .xg {
          position: absolute;
          top: .5rem;
          right: 2rem;
          width: 27rem;
          height: 2.6rem;
          line-height: 2.6rem;

          .gg {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            text-align: center;
            background-color: rgba(28, 184, 184, 1);
            border-radius: .4rem;
            margin-left: 1rem;
          }
        }
      }

      .m_3:last-child {
        margin-bottom: 1rem;
      }

    }
    .main:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }
</style>
